<!--
* @description:
* @fileName pageEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="500px" @close="close">
    <el-form ref="formRef" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="模版名称" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>

      <el-form-item label="模版封面" prop="pageImage" :label-width="90">
        <el-upload class="upload-demo" action="#" list-type="picture-card" :limit="1" :file-list="form.imgList" :http-request="upLogo" :on-error="handleError" :on-exceed="handleExceed1" :on-preview="handlePreview" :on-remove="handleRemove1" accept=".png,.jpeg,.jpg">
          <el-icon class="el-icon--upload">
            <upload-filled />
          </el-icon>
          <div class="el-upload__text">
            <em>点击上传图片</em>
          </div>
          <!-- <template #tip>
              <div class="el-upload__tip">
                jpg/png files with a size less than 500kb
              </div>
            </template> -->
        </el-upload>

        <!-- <el-image
            :src="form.bannerUrl"
            :fit="'fill'"
            v-else
            style="width: 360px; height: 180px"
          ></el-image> -->
      </el-form-item>

      <el-form-item label="模版类型" prop="type">
        <el-select v-model="form.pageType">
          <el-option label="首页" value="HOME"></el-option>
          <el-option label="内页" value="INSIDE"></el-option>
        </el-select>
      </el-form-item>

      <!-- <el-form-item label="所属展会" prop="eventId">
        <el-select v-model="form.eventId">
          <template v-for="(item, index) in eventList" :key="index">
            <el-option
              :label="item.shortName"
              :value="item.eventId"
            ></el-option>
          </template>
        </el-select>
      </el-form-item> -->
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>

  <el-dialog v-model="dialogVisible" append-to-body title="查看大图">
    <div>
      <el-image :src="dialogImageUrl" />
    </div>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from 'vue'
import { upLoadImg } from '@/api/active'
import { addPage, editPage } from '@/api/page'
import { getAllEvent } from '@/api/global'

export default defineComponent({
  name: 'pageEdit',
  emits: ['fetch-data'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      formRef: null,
      treeRef: null,
      checkMenu: [],
      eventList: [],
      form: {
        pageData: '',
        pageType: 'HOME',
        imgList: [],
      },
      rules: {
        name: [{ required: true, trigger: 'blur', message: '请输入模版名称' }],
        eventId: [
          { required: true, trigger: 'change', message: '请选择所属活动' },
        ],
      },
      title: '',
      dialogFormVisible: false,
      dialogVisible: false,
      dialogImageUrl: '',
      list: [],
    })

    const showEdit = async (row) => {
      if (!row) {
        state.title = '添加'
      } else {
        state.title = '编辑'

        state.form = Object.assign({}, row)
        if (row.pageImage) {
          state.form.imgList = [{ name: 'logo', url: row.pageImage }]
        }
        console.log(state.form.imgList)
      }
      state.dialogFormVisible = true
    }
    const close = () => {
      state['formRef'].resetFields()
      state.form = {
        btnRolesCheckedList: [],
      }
      state.dialogFormVisible = false
    }

    const save = () => {
      state['formRef'].validate(async (valid) => {
        if (valid) {
          let msg = ''
          if (state.title == '编辑') {
            let data = await editPage(state.form)
            msg = data.msg
          } else {
            let data = await addPage(state.form)
            msg = data.msg
          }
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          emit('fetch-data')
          close()
        }
      })
    }

    // 上传海报
    const upLogo = async (e) => {
      let upData = new FormData()
      upData.append('file', e.file)
      const { data } = await upLoadImg(upData)
      state.form.pageImage = data
    }

    const handleError = (err, file) => {
      proxy.$baseMessage(
        `文件[${file.raw.name}]上传失败,文件大小为${_.round(
          file.raw.size / 1024,
          0
        )}KB`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleExceed1 = (files) => {
      proxy.$baseMessage(
        `当前限制选择1个文件，本次选择了
             ${files.length}
             个文件`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleRemove1 = (file, fileList) => {
      state.form.pageImage = ''
    }

    const handlePreview = (file) => {
      state.dialogImageUrl = file.url
      console.log(file.url)
      state.dialogVisible = true
    }

    // 获取所有事件
    // const handleGetAllEvent = async () => {
    //   const { data } = await getAllEvent()
    //   state.eventList = data
    // }

    // onMounted(() => {
    //   handleGetAllEvent()
    // })

    return {
      ...toRefs(state),
      showEdit,
      close,
      save,
      upLogo,
      handleError,
      handleExceed1,
      handleRemove1,
      handlePreview,
    }
  },
})
</script>

<style lang="scss" scoped>
.vab-tree-border {
  height: 200px;
  padding: $base-padding;
  overflow-y: auto;
  border: 1px solid #dcdfe6;
  border-radius: $base-border-radius;
}
</style>
